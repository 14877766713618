import { GenericHttpService } from './generic-http.service';
import * as i0 from "@angular/core";
import * as i1 from "./generic-http.service";
var VoucherService = /** @class */ (function () {
    function VoucherService(httpService) {
        this.httpService = httpService;
    }
    VoucherService.prototype.voucherReserve = function (voucherData) {
        var url = '/bems/api/coupon/reserve';
        return this.httpService.post(url, voucherData);
    };
    VoucherService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VoucherService_Factory() { return new VoucherService(i0.ɵɵinject(i1.GenericHttpService)); }, token: VoucherService, providedIn: "root" });
    return VoucherService;
}());
export { VoucherService };
