import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'besafe-enable-notofications',
  templateUrl: './enable-notofications.component.html',
  styleUrls: ['./enable-notofications.component.scss']
})
export class EnableNotoficationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
