import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService && this.authService.userInfo) {
      request = request.clone({
        setHeaders: {
          'x-auth-token': this.authService.userInfo.token || '',
          'x-client-id': this.authService.userInfo.user.fullPhoneNumber || ''
        },
        withCredentials: true
      });
    }


    return next.handle(request);
  }
}
