import { ReplaySubject, Subject } from 'rxjs';
import { GenericHttpService } from './generic-http.service';
import { apiUrls } from '../../config';
import * as i0 from "@angular/core";
import * as i1 from "./generic-http.service";
var geocoder = new google.maps.Geocoder();
var InitService = /** @class */ (function () {
    function InitService(httpService) {
        this.httpService = httpService;
        this.codesOptions = [];
        this.countriesAndCodesSubject = new ReplaySubject(1);
        this.countriesAndCodesSub$ = this.countriesAndCodesSubject.asObservable();
        this._preselectedOption$ = new Subject();
        this.preselectedOption$ = this._preselectedOption$.asObservable();
    }
    InitService.prototype.getCountriesAndCodes = function () {
        var _this = this;
        this.httpService.get(apiUrls.dictionaries.countriesAndCodes)
            .subscribe(function (codes) { return _this.countriesAndCodesSubject.next(codes); });
    };
    InitService.prototype.formCodesOptions = function () {
        var _this = this;
        this.countriesAndCodesSub$.subscribe(function (codes) {
            if (codes.countriesAndCountryCodes) {
                codes.countriesAndCountryCodes.forEach(function (code) {
                    var option = {
                        abbr: code.countryCode,
                        value: code.phoneCountryCode,
                        label: "" + code.abbrAndCode
                    };
                    _this.codesOptions.push(option);
                });
                var compare = function (a, b) {
                    return a.label[0] < b.label[0] ? -1 : a.label[0] > b.label[0] ? 1 : 0;
                };
                _this.codesOptions.sort(compare);
                _this.preselectCountryCode();
            }
        });
        return this.codesOptions;
    };
    InitService.prototype.isMobile = function () {
        return [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]
            .some(function (pattern) { return !!navigator.userAgent.match(pattern); });
    };
    InitService.prototype.isAndroid = function () {
        return [/Android/i]
            .some(function (pattern) { return !!navigator.userAgent.match(pattern); });
    };
    InitService.prototype.isIOs = function () {
        return [/webOS/i, /iPhone/i, /iPad/i, /iPod/i]
            .some(function (pattern) { return !!navigator.userAgent.match(pattern); });
    };
    InitService.prototype.preselectCountryCode = function () {
        var _this = this;
        navigator.geolocation.getCurrentPosition(function (position) {
            var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            geocoder.geocode({ 'latLng': latlng }, function (results, status) {
                if (status !== google.maps.GeocoderStatus.OK) {
                    alert(status);
                }
                // This is checking to see if the Geocode Status is OK before proceeding
                if (status === google.maps.GeocoderStatus.OK) {
                    var currentCountry = results.find(function (res) {
                        return res.types && res.types.indexOf('country') !== -1;
                    });
                    var currentCountryCode_1 = currentCountry.address_components.find(function (address) { return address.types && address.types.indexOf('country') !== -1; }).short_name;
                    _this.codesOptions.find(function (code) { return code.abbr === currentCountryCode_1; });
                    _this._preselectedOption$.next(_this.codesOptions.find(function (code) { return code.abbr === currentCountryCode_1; }));
                }
            });
        });
    };
    InitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InitService_Factory() { return new InitService(i0.ɵɵinject(i1.GenericHttpService)); }, token: InitService, providedIn: "root" });
    return InitService;
}());
export { InitService };
