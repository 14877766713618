import { AuthService } from '@services/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
var JwtInterceptorService = /** @class */ (function () {
    function JwtInterceptorService(authService) {
        this.authService = authService;
    }
    JwtInterceptorService.prototype.intercept = function (request, next) {
        if (this.authService && this.authService.userInfo) {
            request = request.clone({
                setHeaders: {
                    'x-auth-token': this.authService.userInfo.token || '',
                    'x-client-id': this.authService.userInfo.user.fullPhoneNumber || ''
                },
                withCredentials: true
            });
        }
        return next.handle(request);
    };
    JwtInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtInterceptorService_Factory() { return new JwtInterceptorService(i0.ɵɵinject(i1.AuthService)); }, token: JwtInterceptorService, providedIn: "root" });
    return JwtInterceptorService;
}());
export { JwtInterceptorService };
