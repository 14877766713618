import {AfterContentInit, Component, ContentChildren, QueryList} from '@angular/core';
import {AccordionGroupComponent} from './accordion-group/accordion-group.component';
import {WithDestroy} from '@shared/utils/with-destroy';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'besafe-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent extends WithDestroy() implements AfterContentInit {
  @ContentChildren(AccordionGroupComponent)
  groups: QueryList<AccordionGroupComponent>;

  constructor() {
    super();
  }

  ngAfterContentInit() {
    // Set active to first element
    this.groups.toArray()[0].opened = true;
    this.groups.toArray().forEach((t) => {
      t.toggle.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        // Open the group
        this.openGroup(t);
      });
      /*t.toggle.subscribe((group) => {
        // Open the group
        this.openGroup(group);
      });*/
    });
  }

  openGroup(group: AccordionGroupComponent) {
    // close other groups
    this.groups.toArray().forEach((t) => t.opened = false);
    // open current group
    group.opened = true;
  }
}
