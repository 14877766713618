/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-supported.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./not-supported.component";
var styles_NotSupportedComponent = [i0.styles];
var RenderType_NotSupportedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotSupportedComponent, data: {} });
export { RenderType_NotSupportedComponent as RenderType_NotSupportedComponent };
export function View_NotSupportedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "popup-title inverted text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We are so sorry, this browser is not supporting our app :("]))], null, null); }
export function View_NotSupportedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "besafe-not-supported", [], null, null, null, View_NotSupportedComponent_0, RenderType_NotSupportedComponent)), i1.ɵdid(1, 114688, null, 0, i2.NotSupportedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotSupportedComponentNgFactory = i1.ɵccf("besafe-not-supported", i2.NotSupportedComponent, View_NotSupportedComponent_Host_0, {}, {}, []);
export { NotSupportedComponentNgFactory as NotSupportedComponentNgFactory };
