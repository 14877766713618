import { Injectable, NgZone } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import { PolylineDirective } from '../directives/polyline.directive';
import { PolylinePointDirective } from '../directives/polyline-point.directive';
import { GoogleMapsApiWrapperService } from './google-maps-api-wrapper.service';
import { LatLngLiteral } from '../interfaces/lat-lng.interface';
import { Polyline } from '../interfaces/polyline.interface';

@Injectable()
export class PolylineService {
  private _polylines: Map<PolylineDirective, Promise<Polyline>> =
      new Map<PolylineDirective, Promise<Polyline>>();

  constructor(private _mapsWrapper: GoogleMapsApiWrapperService, private _zone: NgZone) {}

  private static _convertPoints(line: PolylineDirective): Array<LatLngLiteral> {
    const path = line._getPoints().map((point: PolylinePointDirective) => {
      return <LatLngLiteral>{lat: point.latitude, lng: point.longitude};
    });
    return path;
  }

  addPolyline(line: PolylineDirective) {
    const path = PolylineService._convertPoints(line);
    const polylinePromise = this._mapsWrapper.createPolyline({
      clickable: line.clickable,
      draggable: line.draggable,
      editable: line.editable,
      geodesic: line.geodesic,
      strokeColor: line.strokeColor,
      strokeOpacity: line.strokeOpacity,
      strokeWeight: line.strokeWeight,
      visible: line.visible,
      zIndex: line.zIndex,
      path: path
    });
    this._polylines.set(line, polylinePromise);
  }

  updatePolylinePoints(line: PolylineDirective): Promise<void> {
    const path = PolylineService._convertPoints(line);
    const m = this._polylines.get(line);
    if (m == null) {
      return Promise.resolve();
    }
    return m.then((l: Polyline) => this._zone.run(() => { l.setPath(path); }) );
  }

  setPolylineOptions(line: PolylineDirective, options: {[propName: string]: any}):
      Promise<void> {
    return this._polylines.get(line).then((l: Polyline) => { l.setOptions(options); });
  }

  deletePolyline(line: PolylineDirective): Promise<void> {
    const m = this._polylines.get(line);
    if (m == null) {
      return Promise.resolve();
    }
    return m.then((l: Polyline) => {
      return this._zone.run(() => {
        l.setMap(null);
        this._polylines.delete(line);
      });
    });
  }

  createEventObservable<T>(eventName: string, line: PolylineDirective): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      this._polylines.get(line).then((l: Polyline) => {
        l.addListener(eventName, (e: T) => this._zone.run(() => observer.next(e)));
      });
    });
  }
}
