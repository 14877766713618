import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { WatchService } from '@services/watch.service';
import { takeUntil } from 'rxjs/operators';
import { GuardianPosition, TwilioCredentials } from '@shared/models/guardians';
import * as Video from 'twilio-video';
import { WithDestroy } from '@shared/utils/with-destroy';

@Component({
  selector: 'besafe-alert-video-stream',
  templateUrl: './alert-video-stream.component.html',
  styleUrls: ['./alert-video-stream.component.scss']
})
export class AlertVideoStreamComponent extends WithDestroy() implements OnInit, OnDestroy {

  @Input() friend: GuardianPosition;
  @Output() isHidden: EventEmitter<any> = new EventEmitter();
  @ViewChild('videoElement', {static: false}) videoElement: ElementRef;
  video = Object.assign({}, Video);
  videoMounted = false;

  public streamStatus: any;
  public streamStatuses = [
    {
      type: 'answer',
      label: 'Turning on',
    },
    {
      type: 'candidate',
      label: 'Live'
    },
    {
      type: 'close',
      label: 'Stream stopped'
    }
  ];
  public isMuted = false;
  public twilioCredentials: TwilioCredentials[] = [];
  public currentTrack: HTMLVideoElement;
  public videoFetchingError = false;

  constructor(
    private watchService: WatchService
  ) {
    super();

  }

  ngOnInit() {
    this.startVideoListening();
  }


  public toggleMute() {
    this.currentTrack.muted = !this.currentTrack.muted;
    this.isMuted = !this.isMuted;
  }

  participantConnected = (participant) => {
    this.videoElement.nativeElement.id = participant.sid;
    participant.on('trackSubscribed', track => this.trackSubscribed(this.videoElement.nativeElement, track));
    participant.on('trackUnsubscribed', this.trackUnsubscribed);

    participant.tracks.forEach(publication => {
      if (publication.isSubscribed) {
        this.trackSubscribed(this.videoElement.nativeElement, publication.track);
      }
    });

  };

  trackSubscribed(div, track) {
    this.currentTrack = track.attach() as HTMLVideoElement;
    this.currentTrack.height = 250;
    this.currentTrack.width = 150;
    this.videoMounted = true;
    div.appendChild(this.currentTrack);
    this.streamStatus = this.streamStatuses[1];
  }

  trackUnsubscribed(track) {
    this.streamStatus = {
      type: 'close',
      label: 'Stream stopped'
    };
    delete this.currentTrack;
    track.detach().forEach(element => element && element.remove());
  }

  participantDisconnected = (participant) => {
    if (document.getElementById(participant.sid)) {
      document.getElementById(participant.sid).remove();
      delete this.currentTrack;
    }
    this.streamStatus = {
      type: 'close',
      label: 'Stream stopped'
    };
  };

  private startVideoListening() {
    this.watchService.twilioCredentials$.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.twilioCredentials = data;
      const credentials = this.twilioCredentials.find(cred => cred.iwatch.hash === this.friend.activeSession.hash);
      this.video.connect(credentials.videoRoom && credentials.videoRoom.accessToken, {
        name: credentials.videoRoom && credentials.videoRoom.roomName,
        audio: false,
        video: false
      }).then(room => {
        room.participants.forEach(this.participantConnected);
        room.on('participantConnected', this.participantConnected);
        room.on('participantDisconnected', this.participantDisconnected);
      }).catch(error => {
        console.error(error);
        this.videoFetchingError = true;
      });
    });
  }


}
