
export const environment = {
  production: true,
  hmr: false,
  baseUrl: 'https://bsafe.bipper.com',
  serverUrl: 'https://bsafe.bipper.com',
  firebase: {
    apiKey: 'AIzaSyAO9qb1hQdw58G2lOyDoGMHn8S3_K49GL0',
    authDomain: 'bsafe-d5d38.firebaseapp.com',
    databaseURL: 'https://bsafe-d5d38.firebaseio.com',
    projectId: 'bsafe-d5d38',
    storageBucket: 'bsafe-d5d38.appspot.com',
    messagingSenderId: '555247676435'
  }
};
