import { NgModule } from '@angular/core';
import { MapModule } from '../map/map.module';
import { MarkerClusterDirective } from './directives/marker-cluster.component';

@NgModule({
  imports: [
    MapModule
  ],
  declarations: [MarkerClusterDirective],
  exports: [MarkerClusterDirective]
})
export class MarkerClustererModule { }
