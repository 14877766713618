import { AuthService } from '@services/auth.service';
import { Injectable } from '@angular/core';
import { GenericHttpService } from '@services/generic-http.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { apiUrls } from '../../config';
import { Guardian, GuardianPosition } from '@shared/models/guardians';

@Injectable({
  providedIn: 'root'
})
export class GuardiansService {
  showGuardiansList$: Observable<void>;
  refreshGuardians$: Observable<void>;
  guardiansLocations$: Observable<GuardianPosition[]>;

  private _guardiansLocations$ = new BehaviorSubject<GuardianPosition[]>([]);
  private _refreshGuardians$ = new Subject<void>();
  private _showGuardiansList$ = new Subject<void>();

  constructor(
    private httpService: GenericHttpService,
    private auth: AuthService
  ) {
    this.refreshGuardians$ = this._refreshGuardians$.asObservable();
    this.guardiansLocations$ = this._guardiansLocations$.asObservable();
    this.showGuardiansList$ = this._showGuardiansList$.asObservable();
  }

  refreshGuardians() {
    this._refreshGuardians$.next();
  }

  showGuardiansList() {
    this._showGuardiansList$.next();
  }

  getAllGuardians(): Observable<any> {
    const url = `${apiUrls.bems.base}${apiUrls.api.base}/${this.auth.userInfo.user.fullPhoneNumber}${apiUrls.guardians.base}`;
    const params = {
      orderFields: 'USER_LAST_NAME,USER_FIRST_NAME',
      order: 'ASC'
    };
    return this.httpService.get(url, params);
  }

  setGuardiansLocations(locations: GuardianPosition[]) {
    this._guardiansLocations$.next(locations);
  }

  getGuardiansPositions(): Observable<any> {
    const url = `${apiUrls.position.user}${this.auth.userInfo.user.fullPhoneNumber}${apiUrls.position.guardians}`;
    return this.httpService.get(url);
  }

  addGuardian(guardian: Guardian): Observable<any> {
    const url = `${apiUrls.bems.base}${apiUrls.api.base}${apiUrls.bems.guardians.base}/${guardian.id}${apiUrls.bems.guardians.accept}`;

    return this.httpService.post(url);
  }

  rejectGuardian(guardian: Guardian): Observable<any> {
    const url = `${apiUrls.bems.base}${apiUrls.api.base}${apiUrls.bems.guardians.base}/${guardian.id}${apiUrls.bems.guardians.reject}`;

    return this.httpService.post(url);
  }

  getImageByPhone(phoneNumber: string) {
    const url = `/${phoneNumber}/images`;
    return this.httpService.get(url);
  }
}
