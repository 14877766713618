export const HistoryActionTypes = {
  alarm: 'ALARM',
  alarmReceived: 'ALARM_RECEIVED',
  followMe: 'FOLLOWME',
  followMeReceived: 'FOLLOWME_RECEIVED',
  iAmHere: 'I_AM_HERE',
  iAmHereReceived: 'I_AM_HERE_RECEIVED',
  addGuardians: 'ADD_GUARDIANS'
};

export const HistoryLabels = {
  ALARM: 'Alarm',
  ALARM_RECEIVED: 'Alarm',
  FOLLOWME: 'Follow me',
  FOLLOWME_RECEIVED: 'Follow me',
  I_AM_HERE: 'I am here',
  I_AM_HERE_RECEIVED: 'I am here'
};

export const AlertTypes = {
  sos: 'SOS_ALARM',
  followMe: 'FOLLOW_ME'
};

export const AlertTypesLabels = {
  SOS_ALARM: 'SOS',
  FOLLOW_ME: 'Follow me'
};
