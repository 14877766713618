var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterContentInit, QueryList } from '@angular/core';
import { AccordionGroupComponent } from './accordion-group/accordion-group.component';
import { WithDestroy } from '@shared/utils/with-destroy';
import { takeUntil } from 'rxjs/operators';
var AccordionComponent = /** @class */ (function (_super) {
    __extends(AccordionComponent, _super);
    function AccordionComponent() {
        return _super.call(this) || this;
    }
    AccordionComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        // Set active to first element
        this.groups.toArray()[0].opened = true;
        this.groups.toArray().forEach(function (t) {
            t.toggle.pipe(takeUntil(_this.onDestroy$)).subscribe(function () {
                // Open the group
                _this.openGroup(t);
            });
            /*t.toggle.subscribe((group) => {
              // Open the group
              this.openGroup(group);
            });*/
        });
    };
    AccordionComponent.prototype.openGroup = function (group) {
        // close other groups
        this.groups.toArray().forEach(function (t) { return t.opened = false; });
        // open current group
        group.opened = true;
    };
    return AccordionComponent;
}(WithDestroy()));
export { AccordionComponent };
