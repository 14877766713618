import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { WithDestroy } from '@shared/utils/with-destroy';
import { takeUntil } from 'rxjs/operators';
import { log } from 'util';

@Component({
  selector: 'besafe-edit-security-base-dialog',
  templateUrl: './edit-security-base-dialog.component.html',
  styleUrls: ['./edit-security-base-dialog.component.scss']
})
export class EditSecurityBaseDialogComponent extends WithDestroy() implements OnInit {
  public sbForm: FormGroup;
  companyCenter: { latitude: any; zoom: number; longitude: any } = {
    longitude: 0,
    latitude: 0,
    zoom: 1
  };

  constructor(private fb: FormBuilder, private authService: AuthService, public dialogRef: MatDialogRef<EditSecurityBaseDialogComponent>) {
    super();
    this.companyCenter.latitude = this.authService.userInfo.user.latitude;
    this.companyCenter.longitude = this.authService.userInfo.user.longitude;
    this.companyCenter.zoom = this.authService.userInfo.user.zoomLevel;
  }

  ngOnInit() {
    this.sbForm = this.initForm();
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const body = {
      phoneNumber: this.authService.userInfo.user.fullPhoneNumber,
      longitude: this.sbForm.value.longitude,
      latitude: this.sbForm.value.latitude,
      zoomLevel: this.sbForm.value.zoomLevel
    };
    this.authService.updateSecurityBasePosition(body).pipe(takeUntil(this.onDestroy$)).subscribe(res => {

      this.dialogRef.close(res);
    });
  }

  handleAddressChange($event) {
    this.companyCenter = {
      latitude: $event.geometry.location.lat(),
      longitude: $event.geometry.location.lng(),
      zoom: 15
    };
    this.setCompanyCenter({
      coords: {
        lng: $event.geometry.location.lng(),
        lat: $event.geometry.location.lat()
      }
    });
  }

  public setZoomLevel(zoom: number) {
    this.sbForm.controls['zoomLevel'].patchValue(zoom);
  }

  public setCompanyCenter(data) {
    this.sbForm.controls['latitude'].patchValue(data.coords.lat);
    this.sbForm.controls['longitude'].patchValue(data.coords.lng);
  }

  private initForm(): FormGroup {
    return this.fb.group({
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      zoomLevel: ['', Validators.required]
    });

  }

}
