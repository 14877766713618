import { GoogleMapsApiWrapperService } from './google-maps-api-wrapper.service';
import { KmlLayer, KmlLayerOptions } from './../interfaces/kml-layer.interface';
import { KmlLayerDirective } from './../directives/kml-layer.directive';
import { Injectable, NgZone } from '@angular/core';
import { Observable, Observer } from 'rxjs';

declare var google: any;

@Injectable()
export class KmlLayerService {
 private _layers: Map<KmlLayerDirective, Promise<KmlLayer>> =
      new Map<KmlLayerDirective, Promise<KmlLayer>>();

  constructor(
    private _wrapper: GoogleMapsApiWrapperService,
    private _zone: NgZone
  ) {}

  /**
   * Adds a new KML Layer to the map.
   */
  addKmlLayer(layer: KmlLayerDirective) {
    const newLayer = this._wrapper.getNativeMap().then(m => {
      return new google.maps.KmlLayer(<KmlLayerOptions>{
        clickable: layer.clickable,
        map: m,
        preserveViewport: layer.preserveViewport,
        screenOverlays: layer.screenOverlays,
        suppressInfoWindows: layer.suppressInfoWindows,
        url: layer.url,
        zIndex: layer.zIndex
      });
    });
    this._layers.set(layer, newLayer);
  }

  setOptions(layer: KmlLayerDirective, options: KmlLayerOptions) {
    this._layers.get(layer).then(l => l.setOptions(options));
  }

  deleteKmlLayer(layer: KmlLayerDirective) {
    this._layers.get(layer).then(l => {
      l.setMap(null);
      this._layers.delete(layer);
    });
  }

  /**
   * Creates a Google Maps event listener for the given KmlLayer as an Observable
   */
  createEventObservable<T>(eventName: string, layer: KmlLayerDirective): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      this._layers.get(layer).then((m: KmlLayer) => {
        m.addListener(eventName, (e: T) => this._zone.run(() => observer.next(e)));
      });
    });
  }
}
