import { OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
type Constructor<T> = new (...args: any[]) => T;

export function WithDestroy<T extends Constructor<{}>>(
  base: T = class {} as any
) {
  return class extends base implements OnDestroy {

    get onDestroy$() {
      return this._onDestroy$.asObservable();
    }
    readonly _onDestroy$ = new Subject();

    ngOnDestroy() {
      this._onDestroy$.next(void 0);
      this._onDestroy$.complete();
    }
  };
}
