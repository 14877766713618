var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { GoogleAddressService } from '@services/google-address.service';
import { EventEmitter, OnChanges, OnInit } from '@angular/core';
import { AlertTypesLabels } from '@shared/constants/event-types';
import { WithDestroy } from '@shared/utils/with-destroy';
import { takeUntil } from 'rxjs/operators';
var AlarmInfoListComponent = /** @class */ (function (_super) {
    __extends(AlarmInfoListComponent, _super);
    function AlarmInfoListComponent(googleAddressService) {
        var _this = _super.call(this) || this;
        _this.googleAddressService = googleAddressService;
        _this.stopFollowFriend = new EventEmitter();
        _this.showFriendLocation = new EventEmitter();
        _this.hideAlarm = new EventEmitter;
        _this.alertLabels = AlertTypesLabels;
        return _this;
    }
    AlarmInfoListComponent.prototype.trackByFn = function (index, item) {
        return item.hash;
    };
    AlarmInfoListComponent.prototype.ngOnInit = function () {
    };
    AlarmInfoListComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['followFriends'] && this.followFriends) {
            this.followFriends.forEach(function (followFriend) {
                if (followFriend && followFriend.location && !followFriend.address) {
                    _this.googleAddressService.getAddressFromLocation(followFriend.location).pipe(takeUntil(_this.onDestroy$)).subscribe(function (address) {
                        followFriend.address = address;
                    });
                    followFriend.longtitudeDMS = _this.googleAddressService.getDMS(followFriend.location.longitude, 'long');
                    followFriend.latitudeDMS = _this.googleAddressService.getDMS(followFriend.location.latitude, 'lat');
                }
            });
        }
    };
    AlarmInfoListComponent.prototype.showOnMap = function (friend) {
        var friendToFocus = Object.assign({}, friend);
        this.showFriendLocation.emit(friendToFocus);
    };
    AlarmInfoListComponent.prototype.stopFollowing = function (friend) {
        this.stopFollowFriend.emit(friend);
    };
    return AlarmInfoListComponent;
}(WithDestroy()));
export { AlarmInfoListComponent };
