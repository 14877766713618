import { GoogleAddressService } from '@services/google-address.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { GuardianPosition } from '@shared/models/guardians';
import { AlertTypesLabels } from '@shared/constants/event-types';
import { WithDestroy } from '@shared/utils/with-destroy';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'besafe-alarm-info-list',
  templateUrl: './alarm-info-list.component.html',
  styleUrls: ['./alarm-info-list.component.scss']
})
export class AlarmInfoListComponent extends WithDestroy() implements OnInit, OnChanges {
  @Input() followFriends: GuardianPosition[];
  @Output() stopFollowFriend: EventEmitter<GuardianPosition> = new EventEmitter();
  @Output() showFriendLocation: EventEmitter<GuardianPosition> = new EventEmitter();
  @Output() hideAlarm: EventEmitter<GuardianPosition> = new EventEmitter;

  public alertLabels = AlertTypesLabels;

  constructor(
    public googleAddressService: GoogleAddressService
  ) {
    super();
  }

  trackByFn(index, item) {
    return item.hash;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes['followFriends'] && this.followFriends) {
      this.followFriends.forEach((followFriend: GuardianPosition) => {
        if (followFriend && followFriend.location && !followFriend.address) {
          this.googleAddressService.getAddressFromLocation(followFriend.location).pipe(takeUntil(this.onDestroy$)).subscribe(address => {
            followFriend.address = address;
          });

          followFriend.longtitudeDMS = this.googleAddressService.getDMS(followFriend.location.longitude, 'long');
          followFriend.latitudeDMS = this.googleAddressService.getDMS(followFriend.location.latitude, 'lat');
        }
      });
    }
  }

  public showOnMap(friend: GuardianPosition) {
    const friendToFocus = Object.assign({}, friend);
    this.showFriendLocation.emit(friendToFocus);
  }

  public stopFollowing(friend: GuardianPosition) {
    this.stopFollowFriend.emit(friend);
  }

}
