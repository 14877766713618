var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnChanges, OnInit } from '@angular/core';
import { HistoryActionTypes, HistoryLabels } from '@shared/constants/event-types';
import { GoogleAddressService } from '@services/google-address.service';
import * as moment from 'moment';
import { WithDestroy } from '@shared/utils/with-destroy';
import { takeUntil } from 'rxjs/operators';
import { HistoryService } from '@services/history.service';
var HistoryItemComponent = /** @class */ (function (_super) {
    __extends(HistoryItemComponent, _super);
    function HistoryItemComponent(googleAddressService, historyService) {
        var _this = _super.call(this) || this;
        _this.googleAddressService = googleAddressService;
        _this.historyService = historyService;
        _this.closeActiveItem = new EventEmitter();
        _this.hisoryLabels = HistoryLabels;
        _this.historyActionTypes = HistoryActionTypes;
        return _this;
    }
    HistoryItemComponent.prototype.ngOnInit = function () {
    };
    HistoryItemComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['data'] && this.data) {
            if (this.data.videoUrl) {
                this.historyService.getVideoUrl(this.data.videoUrl.replace('/api', '/bems/api')).pipe(takeUntil(this.onDestroy$)).subscribe(function (video) {
                    _this.videoUrl = video.link;
                    _this.isVideoProcessing = false;
                }, function (err) {
                    _this.videoUrl = null;
                    _this.isVideoProcessing = true;
                });
            }
            else {
                this.videoUrl = null;
            }
            if (this.data.userTrace.coordinates.length) {
                this.googleAddressService.getAddressFromLocation(this.data.userTrace.coordinates[0]).pipe(takeUntil(this.onDestroy$)).subscribe(function (address) {
                    _this.address = address;
                }, function (err) {
                    console.log(err);
                });
                var lastCoordinate = this.data.userTrace.coordinates[this.data.userTrace.coordinates.length - 1];
                this.longtitudeDMS = this.googleAddressService.getDMS(this.data.userTrace.coordinates[0].longitude, 'long');
                this.latitudeDMS = this.googleAddressService.getDMS(this.data.userTrace.coordinates[0].latitude, 'lat');
                this.locationUpdatedPeriod = this.getLastUpdatedDate(this.data.endDateTime, lastCoordinate.date);
            }
        }
    };
    HistoryItemComponent.prototype.callNumber = function (number) {
        window.open("tel:" + number);
    };
    HistoryItemComponent.prototype.copyToClipboard = function (url) {
        window.getSelection().removeAllRanges();
        var mark = document.createElement('mark');
        mark.textContent = url;
        document.body.appendChild(mark);
        var range = document.createRange();
        range.selectNode(mark);
        window.getSelection().addRange(range);
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
        }
        catch (err) {
            console.log('Oops, unable to copy');
        }
        if (mark) {
            document.body.removeChild(mark);
        }
        window.getSelection().removeAllRanges();
    };
    HistoryItemComponent.prototype.getAddress = function (location) {
        return this.googleAddressService.getAddressFromLocation(location);
    };
    HistoryItemComponent.prototype.getLastUpdatedDate = function (alarmDate, locationDate) {
        var diffDays;
        var diffHours;
        var diffMinutes;
        var momentAlarmDate = moment(alarmDate, 'x');
        var momentLocationDate = moment(locationDate, 'x');
        if (locationDate < alarmDate) {
            diffDays = momentAlarmDate.diff(momentLocationDate, 'days');
            if (diffDays > 0) {
                return diffDays + " days";
            }
            else {
                diffHours = momentAlarmDate.diff(momentLocationDate, 'hours');
                if (diffHours > 0) {
                    return diffHours + " hours";
                }
                else {
                    diffMinutes = momentAlarmDate.diff(momentLocationDate, 'minutes');
                    if (diffMinutes > 0) {
                        return diffMinutes + " minutes";
                    }
                    else {
                        return '';
                    }
                }
            }
        }
    };
    return HistoryItemComponent;
}(WithDestroy()));
export { HistoryItemComponent };
