import { apiUrls } from '../../config';
import { AuthService } from './auth.service';
import { GenericHttpService } from './generic-http.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientHistoryItem } from '@shared/models/history.interface';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  public selectedHistoryItem$: Observable<ClientHistoryItem>;
  private _selectedHistoryItem$ = new BehaviorSubject<ClientHistoryItem>(null);

  constructor(
    private httpService: GenericHttpService,
    private auth: AuthService
  ) {
    this.selectedHistoryItem$ = this._selectedHistoryItem$.asObservable();
  }

  setSelectedHistoryItem(historyItem: ClientHistoryItem) {
    this._selectedHistoryItem$.next(historyItem);
  }

  getClientHistory(page?: number) {
    const params = {
      showReceived: true,
      groupBy: 'START_DATE',
      historyType: 'ALARM_RECEIVED',
      p: page
    };
    const url = `${apiUrls.bems.base}${apiUrls.api.base}${apiUrls.history.base}/${this.auth.userInfo.user.fullPhoneNumber}`;
    return this.httpService.get(url, params);
  }

  getHistoryItemByHash(hash: string) {
    const url = `${apiUrls.bems.base}${apiUrls.api.base}${apiUrls.history.base}${apiUrls.history.items}/${hash}`;
    return this.httpService.get(url);
  }

  getAlarmTrace(hash: string) {
    const url = `/m/${hash}`;
    return this.httpService.get(url);
  }

  getUserTraceCoordinates(hash: string) {
    const url = `/m/${hash}/coordinates`;
    return this.httpService.get(url);
  }

  getVideoUrl(url: string) {
    return this.httpService.get(url);
  }

}
