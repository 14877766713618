import { RouterModule } from '@angular/router';
import { InitService } from './services/init.service';
import { FacebookService } from './services/facebook.service';
import { MapModule } from './modules/map/map.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericHttpService } from './services/generic-http.service';
import { CookiesService } from './services/cookies.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ButtonComponent } from './components/button/button.component';
import { InputComponent } from './components/input/input.component';
import { FormMessageComponent } from './components/form-message/form-message.component';
import { HeaderComponent } from './components/header/header.component';
import { SelectComponent } from './components/select/select.component';
import { GuardiansService } from './services/guardians.service';
import { JwtInterceptorService } from '@services/jwt-interceptor.service';
import { AuthGuard } from '@shared/guards/auth.guard';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { AlertVideoStreamComponent } from './components/alert-video-stream/alert-video-stream.component';
import { HistoryService } from '@services/history.service';
import { LocationService } from '@services/location.service';
import { SpinerComponent } from './components/spiner/spiner.component';
import { HistoryItemComponent } from './components/history-item/history-item.component';
import { AlarmInfoListComponent } from './components/alarm-info-list/alarm-info-list.component';
import { GoogleAddressService } from '@services/google-address.service';
import { VoucherService } from '@services/voucher.service';
import { MarkerClustererModule } from './modules/marker-clusterer/marker-clusterer.module';
import { AccordionComponent } from './components/accordion/accordion.component';
import { AccordionGroupComponent } from './components/accordion/accordion-group/accordion-group.component';
import { PushNotificationService } from '@services/push-notification.service';
import { MatButtonModule, MatInputModule, MatSnackBarModule } from '@angular/material';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

const components = [
  ButtonComponent,
  InputComponent,
  FormMessageComponent,
  HeaderComponent,
  SelectComponent,
  ImageUploadComponent,
  AlertVideoStreamComponent,
  SpinerComponent,
  HistoryItemComponent,
  AlarmInfoListComponent,
  AccordionComponent,
  AccordionGroupComponent
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MapModule.forRoot(),
    GooglePlaceModule,

    MarkerClustererModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule
  ],
  declarations: [
    ...components
  ],
  exports: [
    ...components,
    MapModule,
    GooglePlaceModule,
    MarkerClustererModule
  ],
  providers: [
    CookiesService,
    GenericHttpService,
    FacebookService,
    InitService,
    GuardiansService,
    HistoryService,
    LocationService,
    AuthGuard,
    GoogleAddressService,
    VoucherService,
    PushNotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
  ]
})
export class SharedModule {
}
