import {AuthService} from './auth.service';
import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {BehaviorSubject, Observable} from 'rxjs';
import {GenericHttpService} from './generic-http.service';
import {apiUrls} from '../../config';
import {MatDialog} from '@angular/material';
import {EnableNotoficationsComponent} from '@features/enable-notofications/enable-notofications.component';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private httpService: GenericHttpService,
    private auth: AuthService,
    private dialogService: MatDialog
  ) {
    this.angularFireMessaging.messaging.subscribe(
      (messaging) => {
        messaging.onMessage = messaging.onMessage.bind(messaging);
        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      }
    );
  }

  // request permission for notification from firebase cloud messaging
  requestPermission(isLogout?: boolean) {

    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        if (isLogout) {
          this.unregisterPush(token).subscribe(res => {
            this.auth.logout();
          });
        } else {
          this.registerPush(token).subscribe(res => {
          });
        }

      },
      (err) => {
        // this.auth.logout();
        this.dialogService.open(EnableNotoficationsComponent, {disableClose: true, width: '50%'});
        console.error('Unable to get permission to notify. Please enable notifications permission.');
      }
    );
  }

  // hook method when new notification received in foreground
  receiveMessage() {
    return this.angularFireMessaging.messages;
  }

  registerPush(userToken: string): Observable<any> {
    const url = `${apiUrls.bems.base}${apiUrls.api.base}${apiUrls.push.base}${apiUrls.push.register}`;
    const body = {
      phoneNumber: this.auth.userInfo.user.fullPhoneNumber,
      platform: 'WEB',
      regId: userToken
    };

    return this.httpService.post(url, body);
  }

  unregisterPush(userToken: string): Observable<any> {
    const url = `${apiUrls.bems.base}${apiUrls.api.base}${apiUrls.push.base}${apiUrls.push.unregister}`;
    const body = {
      phoneNumber: this.auth.userInfo.user.fullPhoneNumber,
      regId: userToken
    };

    return this.httpService.post(url, body);
  }

  deleteRegistereredPush(userToken: string): Observable<any> {
    const url = `${apiUrls.push.base}${apiUrls.push.register}`;
    const body = {
      phoneNumber: this.auth.userInfo.user.fullPhoneNumber,
      regId: userToken
    };

    return this.httpService.post(url, body);
  }
}
