import { DataOptions, Data } from './../interfaces/data.interface';
import { InfoWindowOptions, InfoWindow } from './../interfaces/info-window.interface';
import { MarkerOptions, Marker } from './../interfaces/marker.interface';
import { LatLngLiteral, LatLngBounds, LatLng, LatLngBoundsLiteral } from './../interfaces/lat-lng.interface';

import { Injectable, NgZone } from '@angular/core';
import { Observable, Observer } from 'rxjs';

import * as mapTypes from '../interfaces/google-map.interface';
import { MapsAPILoader } from './maps-api-loader.service';
import { PolylineOptions, Polyline } from '../interfaces/polyline.interface';

declare var google: any;

@Injectable()
export class GoogleMapsApiWrapperService {

  private _map: Promise<mapTypes.GoogleMap>;
  private _mapResolver: (value?: mapTypes.GoogleMap) => void;

  constructor(private _loader: MapsAPILoader, private _zone: NgZone) {
    this._map =
        new Promise<mapTypes.GoogleMap>((resolve: () => void) => { this._mapResolver = resolve; });
  }

  createMap(el: HTMLElement, mapOptions: mapTypes.MapOptions): Promise<void> {
    return this._zone.runOutsideAngular( () => {
      return this._loader.load().then(() => {
        const map = new google.maps.Map(el, mapOptions);
        this._mapResolver(<mapTypes.GoogleMap>map);
        return;
      });
    });
  }

  setMapOptions(options: mapTypes.MapOptions) {
    this._map.then((m: mapTypes.GoogleMap) => { m.setOptions(options); });
  }

  /**
   * Creates a google map marker with the map context
   */
  createMarker(options: MarkerOptions = <MarkerOptions>{}, addToMap: boolean = true):
      Promise<Marker> {
    return this._map.then((map: mapTypes.GoogleMap) => {
      if (addToMap) {
        options.map = map;
      }
      return new google.maps.Marker(options);
    });
  }

  createInfoWindow(options?: InfoWindowOptions): Promise<InfoWindow> {
    return this._map.then(() => {
      return new google.maps.InfoWindow(options);
    });
  }

  createPolyline(options: PolylineOptions): Promise<Polyline> {
    return this.getNativeMap().then((map: mapTypes.GoogleMap) => {
      const line = new google.maps.Polyline(options);
      line.setMap(map);
      return line;
    });
  }

  /**
   * Creates a new google.map.Data layer for the current map
   */
  createDataLayer(options?: DataOptions): Promise<Data> {
    return this._map.then(m => {
      const data = new google.maps.Data(options);
      data.setMap(m);
      return data;
    });
  }


  subscribeToMapEvent<E>(eventName: string): Observable<E> {
    return new Observable((observer: Observer<E>) => {
      this._map.then((m: mapTypes.GoogleMap) => {
        m.addListener(eventName, (arg: E) => { this._zone.run(() => observer.next(arg)); });
      });
    });
  }

  clearInstanceListeners() {
    this._map.then((map: mapTypes.GoogleMap) => {
      google.maps.event.clearInstanceListeners(map);
    });
  }

  setCenter(latLng: LatLngLiteral): Promise<void> {
    return this._map.then((map: mapTypes.GoogleMap) => map.setCenter(latLng));
  }

  getZoom(): Promise<number> { return this._map.then((map: mapTypes.GoogleMap) => map.getZoom()); }

  getBounds(): Promise<LatLngBounds> {
    return this._map.then((map: mapTypes.GoogleMap) => map.getBounds());
  }

  getMapTypeId(): Promise<mapTypes.MapTypeId> {
    return this._map.then((map: mapTypes.GoogleMap) => map.getMapTypeId());
  }

  setZoom(zoom: number): Promise<void> {
    return this._map.then((map: mapTypes.GoogleMap) => map.setZoom(zoom));
  }

  getCenter(): Promise<LatLng> {
    return this._map.then((map: mapTypes.GoogleMap) => map.getCenter());
  }

  panTo(latLng: LatLng|LatLngLiteral): Promise<void> {
    return this._map.then((map) => map.panTo(latLng));
  }

  panBy(x: number, y: number): Promise<void> {
    return this._map.then((map) => map.panBy(x, y));
  }

  fitBounds(latLng: LatLngBounds|LatLngBoundsLiteral): Promise<void> {
    return this._map.then((map) => map.fitBounds(latLng));
  }

  panToBounds(latLng: LatLngBounds|LatLngBoundsLiteral): Promise<void> {
    return this._map.then((map) => map.panToBounds(latLng));
  }

  /**
   * Returns the native Google Maps Map instance. Be careful when using this instance directly.
   */
  getNativeMap(): Promise<mapTypes.GoogleMap> { return this._map; }

  /**
   * Triggers the given event name on the map instance.
   */
  triggerMapEvent(eventName: string): Promise<void> {
    return this._map.then((m) => google.maps.event.trigger(m, eventName));
  }
}
