import { storageName } from './../../config/index';
import * as i0 from "@angular/core";
var LocalStorageService = /** @class */ (function () {
    function LocalStorageService() {
        this.appPrefix = storageName.appPrefix;
    }
    LocalStorageService.prototype.setItem = function (param, value) {
        localStorage.setItem(this.appPrefix + "-" + param, value);
    };
    LocalStorageService.prototype.setObject = function (param, value) {
        localStorage.setItem(this.appPrefix + "-" + param, JSON.stringify(value));
    };
    LocalStorageService.prototype.getItem = function (param) {
        return localStorage.getItem(this.appPrefix + "-" + param);
    };
    LocalStorageService.prototype.removeItem = function (param) {
        localStorage.removeItem(this.appPrefix + "-" + param);
    };
    LocalStorageService.prototype.clear = function () {
        localStorage.clear();
    };
    LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(); }, token: LocalStorageService, providedIn: "root" });
    return LocalStorageService;
}());
export { LocalStorageService };
