import { Observable, ReplaySubject, Subject } from 'rxjs';
import { GenericHttpService } from './generic-http.service';
import { Injectable } from '@angular/core';
import { apiUrls } from '../../config';
import { CountriesAndCodes } from '@shared/models/countries-codes';
import { SelectOption } from '@shared/components/select/select-options';

declare var google: any;
const geocoder = new google.maps.Geocoder();

@Injectable({
  providedIn: 'root'
})
export class InitService {
  public codesOptions: SelectOption[] = [];
  private countriesAndCodesSubject = new ReplaySubject<CountriesAndCodes>(1);
  public countriesAndCodesSub$: Observable<CountriesAndCodes> = this.countriesAndCodesSubject.asObservable();
  private _preselectedOption$ = new Subject<SelectOption>();
  public preselectedOption$: Observable<SelectOption> = this._preselectedOption$.asObservable();

  constructor(
    private httpService: GenericHttpService
  ) {
  }

  getCountriesAndCodes() {
    this.httpService.get(apiUrls.dictionaries.countriesAndCodes)
      .subscribe((codes: CountriesAndCodes) => this.countriesAndCodesSubject.next(codes));
  }

  formCodesOptions() {
    this.countriesAndCodesSub$.subscribe((codes: CountriesAndCodes) => {
      if (codes.countriesAndCountryCodes) {
        codes.countriesAndCountryCodes.forEach(code => {
          const option: SelectOption = {
            abbr: code.countryCode,
            value: code.phoneCountryCode,
            label: `${code.abbrAndCode}`
          };
          this.codesOptions.push(option);
        });
        const compare = (a, b) => {
          return a.label[0] < b.label[0] ? -1 : a.label[0] > b.label[0] ? 1 : 0;
        };
        this.codesOptions.sort(compare);
        this.preselectCountryCode();
      }
    });
    return this.codesOptions;
  }

  public isMobile(): boolean {
    return [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]
      .some(pattern => !!navigator.userAgent.match(pattern));
  }

  public isAndroid(): boolean {
    return [/Android/i]
      .some(pattern => !!navigator.userAgent.match(pattern));
  }

  public isIOs(): boolean {
    return [/webOS/i, /iPhone/i, /iPad/i, /iPod/i]
      .some(pattern => !!navigator.userAgent.match(pattern));
  }

  private preselectCountryCode() {
    navigator.geolocation.getCurrentPosition(position => {
      const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
      geocoder.geocode({'latLng': latlng}, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          alert(status);
        }
        // This is checking to see if the Geocode Status is OK before proceeding
        if (status === google.maps.GeocoderStatus.OK) {
          const currentCountry = results.find(res => {
            return res.types && res.types.indexOf('country') !== -1;
          });
          const currentCountryCode = currentCountry.address_components.find(address => address.types && address.types.indexOf('country') !== -1).short_name;
          this.codesOptions.find(code => code.abbr === currentCountryCode);
          this._preselectedOption$.next(this.codesOptions.find(code => code.abbr === currentCountryCode));
        }
      });
    });
  }
}
