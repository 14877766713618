import { DataOptions, Feature } from './../interfaces/data.interface';
import { GoogleMapsApiWrapperService } from './google-maps-api-wrapper.service';
import { DataLayerDirective } from './../directives/data-layer.directive';
import { Injectable, NgZone } from '@angular/core';
import { Observable , Observer } from 'rxjs';
import { Data } from '../interfaces/data.interface';


declare var google: any;


@Injectable()
export class DataLayerService {
  private _layers: Map<DataLayerDirective, Promise<Data>> =
  new Map<DataLayerDirective, Promise<Data>>();

  constructor(
    private _wrapper: GoogleMapsApiWrapperService,
    private _zone: NgZone
  ) { }

  /**
   * Adds a new Data Layer to the map.
   */
  addDataLayer(layer: DataLayerDirective) {
    const newLayer = this._wrapper.createDataLayer(<DataOptions>{
      style: layer.style
    })
    .then(d => {
      if (layer.geoJson) {
        this.getDataFeatures(d, layer.geoJson).then(features => d.features = features);
      }
      return d;
    });
    this._layers.set(layer, newLayer);
  }

  deleteDataLayer(layer: DataLayerDirective) {
    this._layers.get(layer).then(l => {
      l.setMap(null);
      this._layers.delete(layer);
    });
  }

  updateGeoJson(layer: DataLayerDirective, geoJson: Object | string) {
    this._layers.get(layer).then(l => {
      l.forEach(function (feature: Feature) {
        l.remove(feature);

        const index = l.features.indexOf(feature, 0);
        if (index > -1) {
          l.features.splice(index, 1);
        }
      });
      this.getDataFeatures(l, geoJson).then(features => l.features = features);
    });
  }

  setDataOptions(layer: DataLayerDirective, options: DataOptions) {
    this._layers.get(layer).then(l => {
      l.setControlPosition(options.controlPosition);
      l.setControls(options.controls);
      l.setDrawingMode(options.drawingMode);
      l.setStyle(options.style);
    });
  }

  /**
   * Creates a Google Maps event listener for the given DataLayer as an Observable
   */
  createEventObservable<T>(eventName: string, layer: DataLayerDirective): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      this._layers.get(layer).then((d: Data) => {
        d.addListener(eventName, (e: T) => this._zone.run(() => observer.next(e)));
      });
    });
  }

  /**
   * Extract features from a geoJson using google.maps Data Class
   * @param d : google.maps.Data class instance
   * @param geoJson : url or geojson object
   */
  getDataFeatures(d: Data, geoJson: Object | string): Promise<Feature[]> {
    return new Promise<Feature[]>((resolve, reject) => {
        if (typeof geoJson === 'object') {
          try {
            const features = d.addGeoJson(geoJson);
            resolve(features);
          } catch (e) {
            reject(e);
          }
        } else if (typeof geoJson === 'string') {
          d.loadGeoJson(geoJson, null, resolve);
        } else {
          reject(`Impossible to extract features from geoJson: wrong argument type`);
        }
      });
  }
}
