var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject } from 'rxjs';
export function WithDestroy(base) {
    if (base === void 0) { base = /** @class */ (function () {
        function class_1() {
        }
        return class_1;
    }()); }
    return /** @class */ (function (_super) {
        __extends(class_2, _super);
        function class_2() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this._onDestroy$ = new Subject();
            return _this;
        }
        Object.defineProperty(class_2.prototype, "onDestroy$", {
            get: function () {
                return this._onDestroy$.asObservable();
            },
            enumerable: true,
            configurable: true
        });
        class_2.prototype.ngOnDestroy = function () {
            this._onDestroy$.next(void 0);
            this._onDestroy$.complete();
        };
        return class_2;
    }(base));
}
