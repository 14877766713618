var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { MatDialogRef } from '@angular/material';
import { WithDestroy } from '@shared/utils/with-destroy';
import { takeUntil } from 'rxjs/operators';
var EditSecurityBaseDialogComponent = /** @class */ (function (_super) {
    __extends(EditSecurityBaseDialogComponent, _super);
    function EditSecurityBaseDialogComponent(fb, authService, dialogRef) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.authService = authService;
        _this.dialogRef = dialogRef;
        _this.companyCenter = {
            longitude: 0,
            latitude: 0,
            zoom: 1
        };
        _this.companyCenter.latitude = _this.authService.userInfo.user.latitude;
        _this.companyCenter.longitude = _this.authService.userInfo.user.longitude;
        _this.companyCenter.zoom = _this.authService.userInfo.user.zoomLevel;
        return _this;
    }
    EditSecurityBaseDialogComponent.prototype.ngOnInit = function () {
        this.sbForm = this.initForm();
    };
    EditSecurityBaseDialogComponent.prototype.close = function () {
        this.dialogRef.close();
    };
    EditSecurityBaseDialogComponent.prototype.save = function () {
        var _this = this;
        var body = {
            phoneNumber: this.authService.userInfo.user.fullPhoneNumber,
            longitude: this.sbForm.value.longitude,
            latitude: this.sbForm.value.latitude,
            zoomLevel: this.sbForm.value.zoomLevel
        };
        this.authService.updateSecurityBasePosition(body).pipe(takeUntil(this.onDestroy$)).subscribe(function (res) {
            _this.dialogRef.close(res);
        });
    };
    EditSecurityBaseDialogComponent.prototype.handleAddressChange = function ($event) {
        this.companyCenter = {
            latitude: $event.geometry.location.lat(),
            longitude: $event.geometry.location.lng(),
            zoom: 15
        };
        this.setCompanyCenter({
            coords: {
                lng: $event.geometry.location.lng(),
                lat: $event.geometry.location.lat()
            }
        });
    };
    EditSecurityBaseDialogComponent.prototype.setZoomLevel = function (zoom) {
        this.sbForm.controls['zoomLevel'].patchValue(zoom);
    };
    EditSecurityBaseDialogComponent.prototype.setCompanyCenter = function (data) {
        this.sbForm.controls['latitude'].patchValue(data.coords.lat);
        this.sbForm.controls['longitude'].patchValue(data.coords.lng);
    };
    EditSecurityBaseDialogComponent.prototype.initForm = function () {
        return this.fb.group({
            latitude: ['', Validators.required],
            longitude: ['', Validators.required],
            zoomLevel: ['', Validators.required]
        });
    };
    return EditSecurityBaseDialogComponent;
}(WithDestroy()));
export { EditSecurityBaseDialogComponent };
