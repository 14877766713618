import { apiUrls } from '../../config';
import { AuthService } from './auth.service';
import { GenericHttpService } from './generic-http.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./generic-http.service";
import * as i2 from "./auth.service";
var HistoryService = /** @class */ (function () {
    function HistoryService(httpService, auth) {
        this.httpService = httpService;
        this.auth = auth;
        this._selectedHistoryItem$ = new BehaviorSubject(null);
        this.selectedHistoryItem$ = this._selectedHistoryItem$.asObservable();
    }
    HistoryService.prototype.setSelectedHistoryItem = function (historyItem) {
        this._selectedHistoryItem$.next(historyItem);
    };
    HistoryService.prototype.getClientHistory = function (page) {
        var params = {
            showReceived: true,
            groupBy: 'START_DATE',
            historyType: 'ALARM_RECEIVED',
            p: page
        };
        var url = "" + apiUrls.bems.base + apiUrls.api.base + apiUrls.history.base + "/" + this.auth.userInfo.user.fullPhoneNumber;
        return this.httpService.get(url, params);
    };
    HistoryService.prototype.getHistoryItemByHash = function (hash) {
        var url = "" + apiUrls.bems.base + apiUrls.api.base + apiUrls.history.base + apiUrls.history.items + "/" + hash;
        return this.httpService.get(url);
    };
    HistoryService.prototype.getAlarmTrace = function (hash) {
        var url = "/m/" + hash;
        return this.httpService.get(url);
    };
    HistoryService.prototype.getUserTraceCoordinates = function (hash) {
        var url = "/m/" + hash + "/coordinates";
        return this.httpService.get(url);
    };
    HistoryService.prototype.getVideoUrl = function (url) {
        return this.httpService.get(url);
    };
    HistoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HistoryService_Factory() { return new HistoryService(i0.ɵɵinject(i1.GenericHttpService), i0.ɵɵinject(i2.AuthService)); }, token: HistoryService, providedIn: "root" });
    return HistoryService;
}());
export { HistoryService };
