import { AuthService } from './auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { GenericHttpService } from './generic-http.service';
import { apiUrls } from '../../config';
import { MatDialog } from '@angular/material';
import { EnableNotoficationsComponent } from '@features/enable-notofications/enable-notofications.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/database";
import * as i2 from "@angular/fire/auth";
import * as i3 from "@angular/fire/messaging";
import * as i4 from "./generic-http.service";
import * as i5 from "./auth.service";
import * as i6 from "@angular/material/dialog";
var PushNotificationService = /** @class */ (function () {
    function PushNotificationService(angularFireDB, angularFireAuth, angularFireMessaging, httpService, auth, dialogService) {
        this.angularFireDB = angularFireDB;
        this.angularFireAuth = angularFireAuth;
        this.angularFireMessaging = angularFireMessaging;
        this.httpService = httpService;
        this.auth = auth;
        this.dialogService = dialogService;
        this.currentMessage = new BehaviorSubject(null);
        this.angularFireMessaging.messaging.subscribe(function (messaging) {
            messaging.onMessage = messaging.onMessage.bind(messaging);
            messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
        });
    }
    // request permission for notification from firebase cloud messaging
    PushNotificationService.prototype.requestPermission = function (isLogout) {
        var _this = this;
        this.angularFireMessaging.requestToken.subscribe(function (token) {
            if (isLogout) {
                _this.unregisterPush(token).subscribe(function (res) {
                    _this.auth.logout();
                });
            }
            else {
                _this.registerPush(token).subscribe(function (res) {
                });
            }
        }, function (err) {
            // this.auth.logout();
            _this.dialogService.open(EnableNotoficationsComponent, { disableClose: true, width: '50%' });
            console.error('Unable to get permission to notify. Please enable notifications permission.');
        });
    };
    // hook method when new notification received in foreground
    PushNotificationService.prototype.receiveMessage = function () {
        return this.angularFireMessaging.messages;
    };
    PushNotificationService.prototype.registerPush = function (userToken) {
        var url = "" + apiUrls.bems.base + apiUrls.api.base + apiUrls.push.base + apiUrls.push.register;
        var body = {
            phoneNumber: this.auth.userInfo.user.fullPhoneNumber,
            platform: 'WEB',
            regId: userToken
        };
        return this.httpService.post(url, body);
    };
    PushNotificationService.prototype.unregisterPush = function (userToken) {
        var url = "" + apiUrls.bems.base + apiUrls.api.base + apiUrls.push.base + apiUrls.push.unregister;
        var body = {
            phoneNumber: this.auth.userInfo.user.fullPhoneNumber,
            regId: userToken
        };
        return this.httpService.post(url, body);
    };
    PushNotificationService.prototype.deleteRegistereredPush = function (userToken) {
        var url = "" + apiUrls.push.base + apiUrls.push.register;
        var body = {
            phoneNumber: this.auth.userInfo.user.fullPhoneNumber,
            regId: userToken
        };
        return this.httpService.post(url, body);
    };
    PushNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushNotificationService_Factory() { return new PushNotificationService(i0.ɵɵinject(i1.AngularFireDatabase), i0.ɵɵinject(i2.AngularFireAuth), i0.ɵɵinject(i3.AngularFireMessaging), i0.ɵɵinject(i4.GenericHttpService), i0.ɵɵinject(i5.AuthService), i0.ɵɵinject(i6.MatDialog)); }, token: PushNotificationService, providedIn: "root" });
    return PushNotificationService;
}());
export { PushNotificationService };
