<h4 class="inverted mt-0">Edit your security base position</h4>
<mat-form-field appearance="standard" class="w-100 mb-2">
  <mat-label i18n="Address input placeholder@@address-placeholder">Address</mat-label>
  <input #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" matInput
         ngx-google-places-autocomplete/>
</mat-form-field>
<besafe-map
  (zoomChange)="setZoomLevel($event)"
  [latitude]="companyCenter.latitude"
  [longitude]="companyCenter.longitude"
  [mapTypeControl]="true"
  [zoom]="companyCenter.zoom"
  class="map">
  <besafe-marker
    (dragEnd)="setCompanyCenter($event)"
    [label]="'C'"
    [latitude]="companyCenter.latitude"
    [longitude]="companyCenter.longitude"
    [markerDraggable]="true">
  </besafe-marker>
</besafe-map>
<div class="d-flex justify-content-between mt-3">
  <besafe-button (click)="close()" color="secondary">Close</besafe-button>
  <besafe-button (click)="save()" color="primary">Save</besafe-button>
</div>
