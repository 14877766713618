import { Injectable } from '@angular/core';
import { UserPosition } from '@shared/models/user-position';
import { MapsAPILoader } from '@shared/modules/map/services/maps-api-loader.service';
import { Observable } from 'rxjs';
import { LatLngLiteral } from '@shared/modules/map/interfaces/lat-lng.interface';

declare var google;

@Injectable({
  providedIn: 'root'
})
export class GoogleAddressService {

  constructor(
    private mapsAPILoader: MapsAPILoader,
  ) { }

  public getAddressFromLocation(position: UserPosition | any): Observable<string> {
    const addressSearch = {
      location: <LatLngLiteral> {
        lng: Number(position.longitude),
        lat: Number(position.latitude)
      },
      language: 'en'
    };

    return new Observable(observer => {
      try {
        this.mapsAPILoader.load().then(() => {
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode(addressSearch, (results, status): string => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                observer.next(results[0].formatted_address);
                observer.complete();
              } else {
                return 'No address found';
              }
            } else {
              console.log('Geocoder failed due to: ' + status);
              if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                observer.error('Address not found!');
              } else {
                observer.error(status);
              }
            }

          });
        });
      } catch (error) {
        observer.error('error getGeocoding' + error);
        observer.complete();
      }
    });
  }

  // longOrLat - can be lat' or 'long'
  public getDMS(decimalCoordinate: number, longOrLat: string) {
    const hemisphere = /^[WE]|(?:lon)/i.test(longOrLat)
    ? decimalCoordinate < 0
      ? 'W'
      : 'E'
    : decimalCoordinate < 0
      ? 'S'
      : 'N';

    const absDD = Math.abs(decimalCoordinate);
    const degrees = this.truncate(absDD);
    const minutes = this.truncate((absDD - degrees) * 60);
    const seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(2);

    const dmsArray = [degrees, minutes, seconds, hemisphere];
    return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}" ${dmsArray[3]}`;
  }

  private truncate(n) {
    return n > 0 ? Math.floor(n) : Math.ceil(n);
  }
}
