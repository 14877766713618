import { storageName } from './../../config/index';
import { Router } from '@angular/router';
import { FacebookLoginModel } from './../models/facebook-login';
import { GenericHttpService } from './generic-http.service';
import { Injectable } from '@angular/core';
import { apiUrls } from '../../config';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookiesService } from '@services/cookies.service';
import { UserLoginInfo, UserPhone } from '@shared/models/userLoginInfo';
import { LocalStorageService } from '@services/local-storage.service';
import { FollowUserInfo } from '@shared/modules/map/interfaces/follow-user-info';

@Injectable()
export class AuthService {
  public userPhone: string;
  public userPhoneObj: UserPhone;
  public userInfo: UserLoginInfo;
  public userInfo$: Observable<UserLoginInfo>;
  public followedUser: FollowUserInfo;
  public userFbLoginData: FacebookLoginModel;
  private _userInfo$: BehaviorSubject<UserLoginInfo>;

  constructor(
    private httpService: GenericHttpService,
    private cookiesService: CookiesService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    this.userInfo = JSON.parse(this.localStorageService.getItem(storageName.user));
    this._userInfo$ = new BehaviorSubject<UserLoginInfo>(this.userInfo);
    this.userInfo$ = this._userInfo$.asObservable();
    this.userPhone = this.userInfo && this.userInfo.user ? this.userInfo.user.fullPhoneNumber : '';
    this.userPhoneObj = {};
    this.userInfo$.subscribe(userInfo => {
      this.userInfo = userInfo;
    });
  }

  login(loginData): Observable<any> {
    return this.httpService.post(`${apiUrls.login}`, loginData)
      .pipe(
        tap(this.handleLogin.bind(this))
      );
  }

  facebookLogin(userName: string, accessToken: string): Observable<any> {
    const fbLoginModel: FacebookLoginModel = {
      userName,
      accessToken
    };

    this.userFbLoginData = Object.assign({}, fbLoginModel);

    return this.httpService.post(apiUrls.facebookLogin, fbLoginModel)
      .pipe(
        tap(this.handleFacebookLogin.bind(this))
      );
  }

  signUp(userData: any): Observable<any> {
    return this.httpService.post(apiUrls.user.base, userData);
  }

  checkPhoneNumber(phoneNumber: string): Observable<any> {
    const url = `${apiUrls.user.base}/${phoneNumber}${apiUrls.user.checkPhoneNumber}`;
    return this.httpService.get(url);
  }

  sendVerificationCode(phoneNumber: string): Observable<any> {
    const url = `${apiUrls.user.base}/${phoneNumber}${apiUrls.user.sendVerificationCode}`;
    return this.httpService.post(url, {});
  }

  verifyPhoneNumber(verifyObj) {
    const url = `${apiUrls.user.base}${apiUrls.user.verifyPhoneNumber}`;
    return this.httpService.post(url, verifyObj);
  }

  logout(): void {
    this.localStorageService.clear();
    this.cookiesService.deleteCookie('JSESSIONID');
    this.cookiesService.deleteCookie('SESSION');
    this.userPhone = '';
    this.userPhoneObj = {};
    this.router.navigate(['/auth']);
    // Need this to clear auth data
    location.reload();
  }

  createSecurityUser(userData) {
    return this.httpService.post(apiUrls.user.securityGuard, userData);
  }

  updateSecurityBasePosition(body) {
    return this.httpService.put(apiUrls.user.securityGuard, body);
  }

  handleLogin(userInfo: UserLoginInfo): void {
    this.localStorageService.setObject(storageName.user, userInfo);
    this.userPhoneObj.code = userInfo.user.countryCode;
    this.userPhoneObj.phone = userInfo.user.phoneNumber;
    this.setUserInfo(userInfo);
    // this.cookiesService.setCookie('', response.accessToken);
    // this.changeAuth(true);
  }

  setUserInfo(userInfo: UserLoginInfo) {
    this._userInfo$.next(userInfo);
  }

  private handleFacebookLogin(facebookInfo) {
    this.userInfo = facebookInfo;
    this.localStorageService.setObject(storageName.user, facebookInfo);
  }
}
