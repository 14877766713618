import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from '@features/page-not-found/page-not-found.component';
// import { LandingComponent } from '@features/landing/landing.component';
import {AuthGuard} from '@shared/guards/auth.guard';

const routes: Routes = [
  {path: 'auth', loadChildren: 'app/features/auth/auth.module#AuthModule'},
  // { path: 'voucher-connect', loadChildren: 'app/features/voucher/voucher.module#VoucherModule'},
  {
    path: 'dashboard',
    loadChildren: 'app/features/dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},

  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
