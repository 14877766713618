import { AuthService } from '@services/auth.service';
import { GenericHttpService } from '@services/generic-http.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { apiUrls } from '../../config';
import * as i0 from "@angular/core";
import * as i1 from "./generic-http.service";
import * as i2 from "./auth.service";
var GuardiansService = /** @class */ (function () {
    function GuardiansService(httpService, auth) {
        this.httpService = httpService;
        this.auth = auth;
        this._guardiansLocations$ = new BehaviorSubject([]);
        this._refreshGuardians$ = new Subject();
        this._showGuardiansList$ = new Subject();
        this.refreshGuardians$ = this._refreshGuardians$.asObservable();
        this.guardiansLocations$ = this._guardiansLocations$.asObservable();
        this.showGuardiansList$ = this._showGuardiansList$.asObservable();
    }
    GuardiansService.prototype.refreshGuardians = function () {
        this._refreshGuardians$.next();
    };
    GuardiansService.prototype.showGuardiansList = function () {
        this._showGuardiansList$.next();
    };
    GuardiansService.prototype.getAllGuardians = function () {
        var url = "" + apiUrls.bems.base + apiUrls.api.base + "/" + this.auth.userInfo.user.fullPhoneNumber + apiUrls.guardians.base;
        var params = {
            orderFields: 'USER_LAST_NAME,USER_FIRST_NAME',
            order: 'ASC'
        };
        return this.httpService.get(url, params);
    };
    GuardiansService.prototype.setGuardiansLocations = function (locations) {
        this._guardiansLocations$.next(locations);
    };
    GuardiansService.prototype.getGuardiansPositions = function () {
        var url = "" + apiUrls.position.user + this.auth.userInfo.user.fullPhoneNumber + apiUrls.position.guardians;
        return this.httpService.get(url);
    };
    GuardiansService.prototype.addGuardian = function (guardian) {
        var url = "" + apiUrls.bems.base + apiUrls.api.base + apiUrls.bems.guardians.base + "/" + guardian.id + apiUrls.bems.guardians.accept;
        return this.httpService.post(url);
    };
    GuardiansService.prototype.rejectGuardian = function (guardian) {
        var url = "" + apiUrls.bems.base + apiUrls.api.base + apiUrls.bems.guardians.base + "/" + guardian.id + apiUrls.bems.guardians.reject;
        return this.httpService.post(url);
    };
    GuardiansService.prototype.getImageByPhone = function (phoneNumber) {
        var url = "/" + phoneNumber + "/images";
        return this.httpService.get(url);
    };
    GuardiansService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuardiansService_Factory() { return new GuardiansService(i0.ɵɵinject(i1.GenericHttpService), i0.ɵɵinject(i2.AuthService)); }, token: GuardiansService, providedIn: "root" });
    return GuardiansService;
}());
export { GuardiansService };
