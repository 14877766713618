import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { MatDialog, MatSnackBar } from '@angular/material';
import { NotSupportedComponent } from '@features/not-supported/not-supported.component';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(private dialogService: MatDialog,
              private swUpdate: SwUpdate,
              private _snackBar: MatSnackBar,
              private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    if (!firebase.messaging.isSupported()) {
      this.dialogService.open(NotSupportedComponent, {disableClose: true, width: '50%'});
    }
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        console.log('new version is ready');
        this.authService.logout();
        this._snackBar.open('New version has been downloaded, please log in again', 'OK');
      });
    }
  }
}
