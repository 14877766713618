import { InfoWindowOptions } from './../interfaces/info-window.interface';
import { MarkerService } from './marker.service';
import { GoogleMapsApiWrapperService } from './google-maps-api-wrapper.service';
import { InfoWindowComponent } from './../components/info-window/info-window.component';
import { Observable, Observer } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { InfoWindow } from './../interfaces/info-window.interface';


@Injectable()
export class InfoWindowService {
private _infoWindows: Map<InfoWindowComponent, Promise<InfoWindow>> =
      new Map<InfoWindowComponent, Promise<InfoWindow>>();

  constructor(
      private _mapsWrapper: GoogleMapsApiWrapperService, private _zone: NgZone,
      private _markerManager: MarkerService) {}

  deleteInfoWindow(infoWindow: InfoWindowComponent): Promise<void> {
    const iWindow = this._infoWindows.get(infoWindow);
    if (iWindow == null) {
      // info window already deleted
      return Promise.resolve();
    }

    return iWindow.then((i: InfoWindow) => {
      return this._zone.run(() => {
        i.close();
        this._infoWindows.delete(infoWindow);
      });
    });
  }

  setPosition(infoWindow: InfoWindowComponent): Promise<void> {
    return this._infoWindows.get(infoWindow).then((i: InfoWindow) => i.setPosition({
      lat: infoWindow.latitude,
      lng: infoWindow.longitude
    }));
  }

  setZIndex(infoWindow: InfoWindowComponent): Promise<void> {
    return this._infoWindows.get(infoWindow)
        .then((i: InfoWindow) => i.setZIndex(infoWindow.zIndex));
  }

  open(infoWindow: InfoWindowComponent): Promise<void> {
    return this._infoWindows.get(infoWindow).then((w) => {
      if (infoWindow.hostMarker != null) {
        return this._markerManager.getNativeMarker(infoWindow.hostMarker).then((marker) => {
          return this._mapsWrapper.getNativeMap().then((map) => w.open(map, marker));
        });
      }
      return this._mapsWrapper.getNativeMap().then((map) => w.open(map));
    });
  }

  close(infoWindow: InfoWindowComponent): Promise<void> {
    return this._infoWindows.get(infoWindow).then((w) => w.close());
  }

  setOptions(infoWindow: InfoWindowComponent, options: InfoWindowOptions) {
    return this._infoWindows.get(infoWindow).then((i: InfoWindow) => i.setOptions(options));
  }

  addInfoWindow(infoWindow: InfoWindowComponent) {
    const options: InfoWindowOptions = {
      content: infoWindow.content,
      maxWidth: infoWindow.maxWidth,
      zIndex: infoWindow.zIndex,
      disableAutoPan: infoWindow.disableAutoPan
    };
    if (typeof infoWindow.latitude === 'number' && typeof infoWindow.longitude === 'number') {
      options.position = {lat: infoWindow.latitude, lng: infoWindow.longitude};
    }
    const infoWindowPromise = this._mapsWrapper.createInfoWindow(options);
    this._infoWindows.set(infoWindow, infoWindowPromise);
  }

   /**
    * Creates a Google Maps event listener for the given InfoWindow as an Observable
    */
  createEventObservable<T>(eventName: string, infoWindow: InfoWindowComponent): Observable<T> {
    return new Observable((observer: Observer<T>) => {
      this._infoWindows.get(infoWindow).then((i: InfoWindow) => {
        i.addListener(eventName, (e: T) => this._zone.run(() => observer.next(e)));
      });
    });
  }
}
