export const config = {
  accessTokenKey: 'accessToken'
};

export const storageName = {
  appPrefix: 'besafe',
  user: 'user',
  userFBLoginData: 'userFBLoginData',
  userFBProfileInfo: 'userFBProfileInfo'
};

export const positionInterval = {
  base: 60000,
  follow: 25000
};

export const apiUrls = {
  login: '/bems/login',
  facebookLogin: '/login-facebook',
  user: {
    base: '/user',
    baseVersion: '/user/v7',
    checkPhoneNumber: '/checkPhoneNumber',
    verifyPhoneNumber: '/verifyPhoneNumber',
    sendVerificationCode: '/sendVerificationCode',
    securityGuard: '/bems/api/security2'
  },
  checkPhone: '/checkPhoneNumber',
  dictionaries: {
    countries: '/dictionaries/countrycodes',
    countriesAndCodes: '/dictionaries/supported-countries-and-codes'
  },
  api: {
    base: '/api'
  },
  bems: {
    base: '/bems',
    guardians: {
      base: '/guardians',
      accept: '/accept',
      reject: '/reject',
    }
  },
  guardians: {
    base: '/guardiansv2',
    add: '/add',
    remind: '/remind'
  },
  position: {
    user: '/position/user/',
    guardians: '/guardians'
  },
  follow: {
    base: '/followme',
    start: '/start',
    stop: '/stop'
  },
  watch: {
    base: '/iwatch',
    stop: '/stop'
  },
  history: {
    base: '/client-history',
    items: '/items'
  },
  push: {
    base: '/push',
    register: '/register',
    unregister: '/unregister'
  }
};
