import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ClientHistoryItem } from '@shared/models/history.interface';
import { HistoryActionTypes, HistoryLabels } from '@shared/constants/event-types';
import { GoogleAddressService } from '@services/google-address.service';
import * as moment from 'moment';
import { WithDestroy } from '@shared/utils/with-destroy';
import { takeUntil } from 'rxjs/operators';
import { HistoryService } from '@services/history.service';

@Component({
  selector: 'besafe-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss']
})
export class HistoryItemComponent extends WithDestroy() implements OnInit, OnChanges {
  @Input() data: ClientHistoryItem;
  @Output() closeActiveItem: EventEmitter<boolean> = new EventEmitter();

  public hisoryLabels = HistoryLabels;
  public historyActionTypes = HistoryActionTypes;
  public address: string;
  public longtitudeDMS: string;
  public latitudeDMS: string;
  public locationUpdatedPeriod: string;
  public videoUrl: string;
  public isVideoProcessing: boolean;

  constructor(
    public googleAddressService: GoogleAddressService,
    private historyService: HistoryService
  ) {
    super();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes['data'] && this.data) {
      if (this.data.videoUrl) {
        this.historyService.getVideoUrl(this.data.videoUrl.replace('/api', '/bems/api')).pipe(takeUntil(this.onDestroy$)).subscribe(video => {
          this.videoUrl = video.link;
          this.isVideoProcessing = false;
        }, err => {
          this.videoUrl = null;
          this.isVideoProcessing = true;
        });
      } else {
        this.videoUrl = null;
      }
      if (this.data.userTrace.coordinates.length) {
        this.googleAddressService.getAddressFromLocation(this.data.userTrace.coordinates[0]).pipe(takeUntil(this.onDestroy$)).subscribe(address => {
          this.address = address;
        }, err => {
          console.log(err);
        });

        const lastCoordinate = this.data.userTrace.coordinates[this.data.userTrace.coordinates.length - 1];
        this.longtitudeDMS = this.googleAddressService.getDMS(this.data.userTrace.coordinates[0].longitude, 'long');
        this.latitudeDMS = this.googleAddressService.getDMS(this.data.userTrace.coordinates[0].latitude, 'lat');
        this.locationUpdatedPeriod = this.getLastUpdatedDate(this.data.endDateTime, lastCoordinate.date);
      }
    }
  }

  public callNumber(number: string) {
    window.open(`tel:${number}`);
  }

  public copyToClipboard(url: string): any {
    window.getSelection().removeAllRanges();
    const mark = document.createElement('mark');

    mark.textContent = url;
    document.body.appendChild(mark);
    const range = document.createRange();

    range.selectNode(mark);
    window.getSelection().addRange(range);

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
      console.log('Oops, unable to copy');
    }
    if (mark) {
      document.body.removeChild(mark);
    }

    window.getSelection().removeAllRanges();
  }

  public getAddress(location) {
    return this.googleAddressService.getAddressFromLocation(location);
  }

  public getLastUpdatedDate(alarmDate, locationDate) {
    let diffDays: number;
    let diffHours: number;
    let diffMinutes: number;
    const momentAlarmDate = moment(alarmDate, 'x');
    const momentLocationDate = moment(locationDate, 'x');

    if (locationDate < alarmDate) {
      diffDays = momentAlarmDate.diff(momentLocationDate, 'days');

      if (diffDays > 0) {
        return `${diffDays} days`;
      } else {
        diffHours = momentAlarmDate.diff(momentLocationDate, 'hours');

        if (diffHours > 0) {
          return `${diffHours} hours`;
        } else {
          diffMinutes = momentAlarmDate.diff(momentLocationDate, 'minutes');

          if (diffMinutes > 0) {
            return `${diffMinutes} minutes`;
          } else {
            return '';
          }
        }
      }
    }
  }

}
