/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../services/marker.service";
import * as i3 from "../../services/google-maps-api-wrapper.service";
import * as i4 from "../../services/info-window.service";
import * as i5 from "../../services/kml-layer.service";
import * as i6 from "../../services/data-layer.service";
import * as i7 from "../../services/polyline.service";
import * as i8 from "../../services/maps-api-loader.service";
import * as i9 from "../../services/fit-bounds.service";
import * as i10 from "./map.component";
var styles_MapComponent = [i0.styles];
var RenderType_MapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
export function View_MapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "map-container-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "map-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_MapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "besafe-map", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i1.ɵprd(4608, null, i2.MarkerService, i2.MarkerService, [i3.GoogleMapsApiWrapperService, i1.NgZone]), i1.ɵprd(4608, null, i4.InfoWindowService, i4.InfoWindowService, [i3.GoogleMapsApiWrapperService, i1.NgZone, i2.MarkerService]), i1.ɵprd(4608, null, i5.KmlLayerService, i5.KmlLayerService, [i3.GoogleMapsApiWrapperService, i1.NgZone]), i1.ɵprd(4608, null, i6.DataLayerService, i6.DataLayerService, [i3.GoogleMapsApiWrapperService, i1.NgZone]), i1.ɵprd(4608, null, i7.PolylineService, i7.PolylineService, [i3.GoogleMapsApiWrapperService, i1.NgZone]), i1.ɵprd(512, null, i3.GoogleMapsApiWrapperService, i3.GoogleMapsApiWrapperService, [i8.MapsAPILoader, i1.NgZone]), i1.ɵprd(512, null, i9.FitBoundsService, i9.FitBoundsService, [i8.MapsAPILoader]), i1.ɵdid(8, 770048, null, 0, i10.MapComponent, [i1.ElementRef, i3.GoogleMapsApiWrapperService, i9.FitBoundsService], null, null)], function (_ck, _v) { _ck(_v, 8, 0); }, null); }
var MapComponentNgFactory = i1.ɵccf("besafe-map", i10.MapComponent, View_MapComponent_Host_0, { longitude: "longitude", latitude: "latitude", zoom: "zoom", minZoom: "minZoom", maxZoom: "maxZoom", draggable: "mapDraggable", disableDoubleClickZoom: "disableDoubleClickZoom", disableDefaultUI: "disableDefaultUI", scrollwheel: "scrollwheel", backgroundColor: "backgroundColor", draggableCursor: "draggableCursor", draggingCursor: "draggingCursor", keyboardShortcuts: "keyboardShortcuts", zoomControl: "zoomControl", zoomControlOptions: "zoomControlOptions", styles: "styles", usePanning: "usePanning", streetViewControl: "streetViewControl", streetViewControlOptions: "streetViewControlOptions", fitBounds: "fitBounds", scaleControl: "scaleControl", scaleControlOptions: "scaleControlOptions", mapTypeControl: "mapTypeControl", mapTypeControlOptions: "mapTypeControlOptions", panControl: "panControl", panControlOptions: "panControlOptions", rotateControl: "rotateControl", rotateControlOptions: "rotateControlOptions", fullscreenControl: "fullscreenControl", fullscreenControlOptions: "fullscreenControlOptions", mapTypeId: "mapTypeId", clickableIcons: "clickableIcons", gestureHandling: "gestureHandling" }, { mapClick: "mapClick", mapRightClick: "mapRightClick", mapDblClick: "mapDblClick", centerChange: "centerChange", boundsChange: "boundsChange", mapTypeIdChange: "mapTypeIdChange", idle: "idle", zoomChange: "zoomChange", mapReady: "mapReady" }, ["*"]);
export { MapComponentNgFactory as MapComponentNgFactory };
