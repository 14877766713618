import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeEn from '@angular/common/locales/en';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptorService } from '@services/jwt-interceptor.service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { NotSupportedComponent } from './features/not-supported/not-supported.component';
import {
  MatCardModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
} from '@angular/material';
import { EnableNotoficationsComponent } from './features/enable-notofications/enable-notofications.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EditSecurityBaseDialogComponent } from '@features/dashboard/edit-security-base-dialog/edit-security-base-dialog.component';
import { AuthService } from '@services/auth.service';


registerLocaleData(localeEn, 'en');

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    NotSupportedComponent,
    EnableNotoficationsComponent,
    EditSecurityBaseDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    MatCardModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
  ],
  entryComponents: [NotSupportedComponent, EnableNotoficationsComponent, EditSecurityBaseDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
