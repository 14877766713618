import { MapsAPILoader } from '@shared/modules/map/services/maps-api-loader.service';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../modules/map/services/maps-api-loader.service";
var GoogleAddressService = /** @class */ (function () {
    function GoogleAddressService(mapsAPILoader) {
        this.mapsAPILoader = mapsAPILoader;
    }
    GoogleAddressService.prototype.getAddressFromLocation = function (position) {
        var _this = this;
        var addressSearch = {
            location: {
                lng: Number(position.longitude),
                lat: Number(position.latitude)
            },
            language: 'en'
        };
        return new Observable(function (observer) {
            try {
                _this.mapsAPILoader.load().then(function () {
                    var geocoder = new google.maps.Geocoder();
                    geocoder.geocode(addressSearch, function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            if (results[0]) {
                                observer.next(results[0].formatted_address);
                                observer.complete();
                            }
                            else {
                                return 'No address found';
                            }
                        }
                        else {
                            console.log('Geocoder failed due to: ' + status);
                            if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                                observer.error('Address not found!');
                            }
                            else {
                                observer.error(status);
                            }
                        }
                    });
                });
            }
            catch (error) {
                observer.error('error getGeocoding' + error);
                observer.complete();
            }
        });
    };
    // longOrLat - can be lat' or 'long'
    GoogleAddressService.prototype.getDMS = function (decimalCoordinate, longOrLat) {
        var hemisphere = /^[WE]|(?:lon)/i.test(longOrLat)
            ? decimalCoordinate < 0
                ? 'W'
                : 'E'
            : decimalCoordinate < 0
                ? 'S'
                : 'N';
        var absDD = Math.abs(decimalCoordinate);
        var degrees = this.truncate(absDD);
        var minutes = this.truncate((absDD - degrees) * 60);
        var seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(2);
        var dmsArray = [degrees, minutes, seconds, hemisphere];
        return dmsArray[0] + "\u00B0" + dmsArray[1] + "'" + dmsArray[2] + "\" " + dmsArray[3];
    };
    GoogleAddressService.prototype.truncate = function (n) {
        return n > 0 ? Math.floor(n) : Math.ceil(n);
    };
    GoogleAddressService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleAddressService_Factory() { return new GoogleAddressService(i0.ɵɵinject(i1.MapsAPILoader)); }, token: GoogleAddressService, providedIn: "root" });
    return GoogleAddressService;
}());
export { GoogleAddressService };
