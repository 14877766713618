var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { WatchService } from '@services/watch.service';
import { takeUntil } from 'rxjs/operators';
import * as Video from 'twilio-video';
import { WithDestroy } from '@shared/utils/with-destroy';
var AlertVideoStreamComponent = /** @class */ (function (_super) {
    __extends(AlertVideoStreamComponent, _super);
    function AlertVideoStreamComponent(watchService) {
        var _this = _super.call(this) || this;
        _this.watchService = watchService;
        _this.isHidden = new EventEmitter();
        _this.video = Object.assign({}, Video);
        _this.videoMounted = false;
        _this.streamStatuses = [
            {
                type: 'answer',
                label: 'Turning on',
            },
            {
                type: 'candidate',
                label: 'Live'
            },
            {
                type: 'close',
                label: 'Stream stopped'
            }
        ];
        _this.isMuted = false;
        _this.twilioCredentials = [];
        _this.videoFetchingError = false;
        _this.participantConnected = function (participant) {
            _this.videoElement.nativeElement.id = participant.sid;
            participant.on('trackSubscribed', function (track) { return _this.trackSubscribed(_this.videoElement.nativeElement, track); });
            participant.on('trackUnsubscribed', _this.trackUnsubscribed);
            participant.tracks.forEach(function (publication) {
                if (publication.isSubscribed) {
                    _this.trackSubscribed(_this.videoElement.nativeElement, publication.track);
                }
            });
        };
        _this.participantDisconnected = function (participant) {
            if (document.getElementById(participant.sid)) {
                document.getElementById(participant.sid).remove();
                delete _this.currentTrack;
            }
            _this.streamStatus = {
                type: 'close',
                label: 'Stream stopped'
            };
        };
        return _this;
    }
    AlertVideoStreamComponent.prototype.ngOnInit = function () {
        this.startVideoListening();
    };
    AlertVideoStreamComponent.prototype.toggleMute = function () {
        this.currentTrack.muted = !this.currentTrack.muted;
        this.isMuted = !this.isMuted;
    };
    AlertVideoStreamComponent.prototype.trackSubscribed = function (div, track) {
        this.currentTrack = track.attach();
        this.currentTrack.height = 250;
        this.currentTrack.width = 150;
        this.videoMounted = true;
        div.appendChild(this.currentTrack);
        this.streamStatus = this.streamStatuses[1];
    };
    AlertVideoStreamComponent.prototype.trackUnsubscribed = function (track) {
        this.streamStatus = {
            type: 'close',
            label: 'Stream stopped'
        };
        delete this.currentTrack;
        track.detach().forEach(function (element) { return element && element.remove(); });
    };
    AlertVideoStreamComponent.prototype.startVideoListening = function () {
        var _this = this;
        this.watchService.twilioCredentials$.pipe(takeUntil(this.onDestroy$)).subscribe(function (data) {
            _this.twilioCredentials = data;
            var credentials = _this.twilioCredentials.find(function (cred) { return cred.iwatch.hash === _this.friend.activeSession.hash; });
            _this.video.connect(credentials.videoRoom && credentials.videoRoom.accessToken, {
                name: credentials.videoRoom && credentials.videoRoom.roomName,
                audio: false,
                video: false
            }).then(function (room) {
                room.participants.forEach(_this.participantConnected);
                room.on('participantConnected', _this.participantConnected);
                room.on('participantDisconnected', _this.participantDisconnected);
            }).catch(function (error) {
                console.error(error);
                _this.videoFetchingError = true;
            });
        });
    };
    return AlertVideoStreamComponent;
}(WithDestroy()));
export { AlertVideoStreamComponent };
